export const testimonialV2Fragment = `
  entryOverline
  testimonialEntriesCollection(limit: 12) {
    items {
      testimonial
      author
      authorTitle
      authorImage {
        description
        contentType
        url
        width
        height
      }
    }
  }
  backgroundImage {
    description
    contentType
    url
    width
    height
  }
`

import { storySpotlightFragment } from '@components/story-spotlight/story-spotlight.fragment'
import { slideFragment } from '@schema/slide'
import { marketingFormEmbedFragment } from '@components/marketing-form-embed/marketing-form-embed.fragment'
import gql from 'graphql-tag'
import { testimonialV2Fragment } from '@components/testimonialv2/testimonialv2.fragment'

export const promotedLinkFragment = `
headline
summary
image {
  url
}
externalLink
reference {
  __typename
}
sys {
  id
}
`

const homeFragment = `
  hero {
    ${slideFragment}
  }
  heroSecondaryCtaText
  heroSecondaryCtaLink
  useBackupHero
  promotedLinksCollection(limit: 3) {
    total
    items {
        ${promotedLinkFragment}
    }
  }
  featuredCoachesHeadline
  featuredCoachesCtaText
  featuredCoachesCtaLink
  filteredStoriesHeadline
  filteredStoriesCategoriesCollection {
    items {
      title
      category
      series
      sys {
        id
      }
    }
  }
  filteredStoriesCtaText
  filteredStoriesCtaLink
  testimonialsSection {
    ${testimonialV2Fragment}
  }
  reachHeadline
  reachCtaText
  reachCtaLink
  reachDescription
  reachSlidesCollection {
    total
    items {
      ${slideFragment}
    }
  }
  newsletterSignup {
    ${marketingFormEmbedFragment}
  }
  metaTitle
  metaDescription
  metaImage {
    url
  }
`

export const FEATURED_STORIES_QUERY = gql`
query Homepage($preview: Boolean) {
  homepageCollection(limit: 1, preview: $preview) {
    items {
      featuredStoriesHeadline
      featuredStoriesCtaText
      featuredStoriesCtaLink
      featuredStoriesCollection(limit: 5) {
        total
        items {
         ${storySpotlightFragment}
        }
      }
    }
  }
}
`


export default homeFragment

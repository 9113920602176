export const marketingFormEmbedFragment = `
  headline
  digiohFormId
  socialHeadline
  socialDescription
  socialCollection {
    items {
      title
      link
      headline
    }
  }
 `

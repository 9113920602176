import React from 'react'
import Link from 'next/link'
import Card from '@material-ui/core/Card'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { IconMarkerNew, IconPermIdentity } from '@components/atoms/icons'
import PropTypes from 'prop-types'
import ButtonCta from '@components/atoms/button-cta'
import Image from '@components/atoms/image'
import CardBanner from '@components/coach-card-banner/coach-card-banner'
import { gtmEvent } from '@lib/gtm-utils'
import * as amplitude from '@amplitude/analytics-browser'
import { useRouter } from 'next/router'
import isPgaLink from '@utils/isPgaLink'
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined'
import useStyles from './coach-card.styles'

const CoachCard = ({
  coachInfo, className, isStory = false, source,
}) => {
  const {
    profilePhoto,
    displayName,
    city,
    state,
    customSlug,
    firstName,
    lastName,
    facilities,
    bookable,
    bookingURL,
    bio,
    title,
    hasIntroVideo,
    objectID,
  } = coachInfo

  // Fields for Title will replace Facility Name
  // and a bio is added for the coach When the card is being used
  // in the story layout

  const styles = useStyles({ isStory })
  const router = useRouter()

  const showMeetTheCoachBanner = hasIntroVideo
  const showBookNow = bookingURL && !isStory
  const isExternalLink = !isPgaLink(bookingURL)

  const handleEventing = () => amplitude.track('click-book-now-button', {
    bookable,
    coach_profile_id: objectID,
    coach_name: (firstName && lastName)
      ? `${firstName} ${lastName}`
      : `${displayName}`,
    has_video: hasIntroVideo,
    source,
    page_path: router.asPath,
  })

  const BookNowButton = isExternalLink ? (
    <Button
      variant="contained"
      color="primary"
      className={styles.ctaButtonPair}
      href={bookingURL}
      target="_blank"
      dataAttrs={{
        'data-gtm-event-info': showMeetTheCoachBanner ? 'has-video' : null,
      }}
      onClick={handleEventing}
      endIcon={isExternalLink && <OpenInNewOutlinedIcon fontSize="small" />}
    >
      Book Now
    </Button>
  ) : (
    <ButtonCta
      variant="contained"
      color="primary"
      className={styles.ctaButtonPair}
      href={bookingURL}
      dataAttrs={{
        'data-gtm-event-info': showMeetTheCoachBanner ? 'has-video' : null,
      }}
      onClick={handleEventing}
    >
      Book Now
    </ButtonCta>
  )

  const ctaSelection = () => {
    if (isStory) {
      return (
        <ButtonCta
          variant="text"
          arrow
          className={styles.meetCoachButton}
          href={`/coach/${customSlug}`}
          dataAttrs={{ 'aria-label': `View profile for ${firstName} ${lastName}` }}
        >
          Meet {firstName}
        </ButtonCta>
      )
    }
    if (showBookNow) {
      return (
        <Box className={styles.ctaContainer}>
          <ButtonCta
            variant="text"
            className={styles.ctaButtonPair}
            href={`/coach/${customSlug}`}
            dataAttrs={{ 'aria-label': `View profile for ${firstName} ${lastName}`, 'data-gtm-event-info': showMeetTheCoachBanner ? 'has-video' : null, 'data-gtm-coach-search': true }}
          >
            View Profile
          </ButtonCta>
          {BookNowButton}
        </Box>
      )
    }
    return (
      <ButtonCta
        variant="text"
        arrow
        href={`/coach/${customSlug}`}
        className={styles.ctaButton}
        dataAttrs={{ 'aria-label': `View profile for ${firstName} ${lastName}`, 'data-gtm-event-info': showMeetTheCoachBanner ? 'has-video' : null, 'data-gtm-coach-search': true }}
      >
        View Profile
      </ButtonCta>
    )
  }

  return (
    <Card
      className={`${styles.root} ${className}`}
      onClick={() => gtmEvent({
        event: 'click-coach-card',
        attributes: {
          bookable,
          coach_name: (firstName && lastName)
            ? `${firstName} ${lastName}`
            : `${displayName}`,
          coach_profile_id: objectID,
          has_video: hasIntroVideo,
          source,
        },
      })}
    >
      <Link href={`/coach/${customSlug}`}>
        <a className={styles.contentContainer} data-gtm-event-info={showMeetTheCoachBanner ? 'has-video' : null} data-gtm-coach-search>
          {profilePhoto ? (
            <Image
              data-testid="coachImage"
              className={styles.coachImage}
              src={profilePhoto}
              alt={displayName}
            />
          ) : (
            <IconPermIdentity className={styles.coachImage} />
          )}
          {showMeetTheCoachBanner && <CardBanner>MEET THE COACH</CardBanner>}
          <div className={styles.locationTag}>
            <IconMarkerNew className={styles.iconMarker} viewBox="0 -2 8 17" />
            <Typography className={styles.coachLocation} variant="subtitle2" variantMapping={{ subtitle2: 'p' }}>
              {`${city}, ${state}`}
            </Typography>
          </div>
          <Typography
            className={styles.coachName}
            variant="h5"
            variantMapping={{ h5: 'h3' }}
          >
            {firstName && lastName
              ? `${firstName} ${lastName}`
              : `${displayName}`}
          </Typography>

          {isStory ? title && (
            <Typography variant="caption" className={styles.coachTitle}>
              {title}
            </Typography>
          ) : facilities && (
            <Typography variant="caption" className={styles.facilityName}>
              {facilities[0].facilityName}
            </Typography>
          )}
        </a>
      </Link>
      {bio && (
        <Typography variant="body1" className={styles.bioText}>
          {bio}
        </Typography>
      )}
      {ctaSelection()}
    </Card>
  )
}

CoachCard.propTypes = {
  coachInfo: PropTypes.object,
  className: PropTypes.string,
  isStory: PropTypes.bool,
  hasIntroVideo: PropTypes.bool,
}

CoachCard.defaultProps = {
  className: '',
}

export default CoachCard
